.Newsletter{
  position: relative;

  // Title
  > span{
    font: 700 24px $main-font;
    @include font-limited(24);
    margin-bottom: 1.5em;
  }

  > form{
    .Input{
      label{
        position: absolute;
        top: -99999px;
        left: -99999px;
      }

      input{
        padding: 0.5em 6.5em 0.5em 0;
      }
    }

    .Form__actions{
      button[type="submit"] {
        position: absolute;
        bottom: 0; right: 0;
        transform: translate(10%, 30%);
      }
    }
  }
}