.Popup#EBook{
  &, .EBook{
    transition: transform 750ms $ease,
                opacity 750ms $ease;
  }

  body:not(.--show-ebook) &{
    opacity: 0;
    pointer-events: none;

    .EBook{
      opacity: 0;
      transform: scale(0.85);
      transition-delay: 0ms;
    }
  }

  .EBook{
    @include flex(flex-start);
    position: relative;
    z-index: 2;
    width: 100%;
    max-height: 100%;
    margin: 0 auto;
    gap: 3em;
    background-color: #F1EEDE;
    padding: 1.5em;
    max-width: 1100px;
    transition-delay: 450ms;
    overflow: scroll;

    @include responsiveMinMax($tabletSmall, $deskXXL){
      max-width: get-vw(1100);
    }

    @include responsive($tabletSmall, max){
      flex-direction: column;
      padding: 1.5em;
      gap: 0;
    }

    .visual__container{
      @include imgRatio(350, 450);
      max-width: 340px;
      min-height: 260px;
      
      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(340);
      }
      
      @include responsive($tabletSmall){
        min-height: 100%;
        position: sticky;
        top: 0;
      }
    }

    .wrapper{
      padding: 1.5em 0;

      > h2{
        @include font-limited(34);
        margin-bottom: 0.3em;
      }

      > span{
        font: 400 22px $main-font;
        @include font-limited(24);
      }

      > .wswyg--content{
        max-width: 330px;
        margin: 1em 0;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          max-width: get-vw(330);
        }
      }

      > form{
        position: relative;
        max-width: 400px;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          max-width: get-vw(420);
        }

        .Input{
          label{
            font: 700 2.5vw $main-font;
            @include font-limited(18);
            margin-bottom: 1em;
          }

          input{
            padding: 0.5em 6.5em 0.5em 0;
          }
        }

        .Checkbox{
          margin-top: 1em;
        }

        .Form__actions{
          button[type="submit"] {
            position: absolute;
            top: 0; right: 0;
            transform: translate(15%, 20%);

            .Macaron{
              @include responsive($tabletSmall, max){
                min-width: 80px;
              }
            }
          }
        }

        .Form__output{
          margin-top: 0; 
          
          a{
            text-decoration: underline;
          }
        }
      }
    }
  }
}
