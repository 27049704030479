.AllArticles{
  padding: 5em 0 0;

  .container{
    padding-bottom: 5em;

    @include responsive($tabletSmall){
      padding-left: 5em;
      padding-right: 5em;
    }
  }

  form{
    @include flex(flex-start);
    flex-wrap: wrap;
    gap: 1em;
    margin-bottom: 3.5em;
  }

  .grid{
    display: grid;
    grid-gap: 3em;
    width: 100%;
    
    @include responsive($tabletSmall){
      grid-template-columns: repeat(10, 1fr);
      grid-gap: 2em;
    }

    > *{
      @include responsive($tabletSmall){
        grid-column: span 4;
      }

      &:nth-child(4n + 2),
      &:nth-child(4n + 3){
        @include responsive($tabletSmall){
          grid-column: span 6;
        }
      }
    }
  }

}
