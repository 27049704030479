.SingleContent{
  padding-bottom: 0;

  .container{
    .wswyg--content{
      > *{
        width: 100%;
        max-width: 445px;
        margin-left: auto;
        margin-right: auto;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          max-width: get-vw(445);
        }
      }

      > h2,
      > h3, 
      > h4,
      > h5,
      > h6,
      > div:has(img),
      > p:has(img){
        text-align: center;
        max-width: 670px;
        margin-left: auto;
        margin-right: auto;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          max-width: get-vw(670);
        }
        
        & + p,
        & + ul,
        & + ol{
          margin-top: 2em;
        }
      }

      p:has(img),
      p:has(.plyr__container) {
        max-width: 970px;
        
        @include responsiveMinMax($tabletSmall, $deskXXL){
          max-width: get-vw(970);
        }
      }

      div:has(img),
      p:has(img){
        margin: 3.5em auto;

        img{
          width: 100%;
          border-radius: 10px;
        }
      }

      span[style] {
        border-radius: 3px;
        padding: 0 0.25em;
      }
    }
  }
}

