.PageContent{
  .wswyg--content{
    max-width: 850px;
    margin: 0 auto;

    @include responsiveMinMax($tabletSmall, $deskXXL){
      max-width: get-vw(850);
    }
  }
}
