.Checkbox{
  @include flex(flex-start);
  gap: 0.75em;
  padding: 0.75em 0 0.75em;
  position: relative;
  transition: transform 450ms $ease;

  > input{
    all: unset;
    @include cover();
    cursor: pointer;
    border-radius: 10px;

    &:checked{
      & ~ .check{
        background: $nuit-ete-base;
        border-color: $nuit-ete-base;

        .Icon.--tick{
          opacity: 1;
          transform: scale(1);
        }
      }

      & ~ span{
        opacity: 1;
      }
    }
  }

  > input,
  > .check{
    transition: border-color 500ms $ease,
                background 500ms $ease;
  }

  > .check,
  > span{
    position: relative;
    z-index: 1;
    pointer-events: none;
  }

  > .check{
    @include flex(center, center);
    height: get-vw(24);
    width: get-vw(24);
    border: 1.5px solid rgba($nuit-ete-base, 0.35);
    border-radius: 3px; 
    margin-top: 0.35em;

    @include responsive($tabletSmall, max){
      min-height: 24px;
      min-width: 24px;
    }

    @include responsive($desk){
      max-width: 24px;
      max-height: 24px;
    }

    .Icon.--tick {
      opacity: 0;
      transform: scale(0.2);
      transition: transform 250ms $ease;

      svg > *{
        stroke: $white;
      }
    }
  }
        
  > span{
    @include font-limited(17);
    transition: opacity 500ms $ease;
    flex: 1;
    opacity: 0.6;
  }
}
