$menuBreakpoint: $tabletSmall;

.Header {
  position: fixed;
  top: calc( 0 + var(--wp-admin--admin-bar--height)); left: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none;

  body:not(.--animating) &{
    transition: background-color 500ms $ease,
                box-shadow 500ms $ease,
                clip-path 500ms $ease,
                transform 500ms $ease;
  }

  // When header is hiding
  &.--hide{
    transform: translate(0, -100%);
  }

  body.--show-menu &,
  body.--show-submenu &:not(.--scroll) {
    .Brand .MainLogo{
      svg path{
        fill: $white!important;
      }
    }
  }

  // When header is scrolling
  &.--scroll{
    &:not(.--hide) {
      transform: none;
      background: $white;

      .container {
        @include responsive($tabletSmall){
          height: calc(get-vw(60) + 2em);
        }
      }

      .Nav {
        gap: get-vw(140);
      }

      .Brand .MainLogo{
        width: 40px;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          width: get-vw(40);
        }

        svg > *{
          fill: $nuit-ete-base;
        }
      }
    }
  }

  &.--dark {
    .Brand .MainLogo{
      svg path{
        fill: $nuit-ete-base;
      }
    }
  }

  &.--rose {
    .Brand .MainLogo{
      svg path{
        fill: $terre-cuite-base;
      }
    }
  }

  .container {
    @include flex(center, space-between);
    width: 100%;
    max-width: none;
    padding: 20px;
    transition: height 420ms $ease;
    
    @include responsive($tabletSmall){
      padding: 1em 2em;
      justify-content: center;
      height: calc(get-vw(100) + 2em);
    }
  }

  .Brand {
    z-index: 200;
    @include responsive($tabletSmall){
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }
}
