.PriceCard{
  padding: 2.5em;
  width: 100%;
  max-width: 900px;

  @include responsiveMinMax($tabletSmall, $deskXXL){
    max-width: get-vw(900);
  }

  @include responsive($tabletSmall){
    padding: 5em; 
  }

  /*
  * Themes
  */

  @each $slug, $background, $color in $prices-themes {
    &[data-theme="#{$slug}"]{
      background-color: $background;
      color: $color;
    }
  }

  .wrapper{
    max-width: 444px;

    @include responsiveMinMax($tabletSmall, $deskXXL){
      max-width: get-vw(444);
    }

    // Title
    > span{
      font: 700 24px $main-font;
      @include font-limited(40);
      padding-bottom: 1em;

      sup{
        opacity: 0.6;
        font-size: 0.5em;
      }
    }

    // Content
    > .wswyg--content{
      font-size: 20px;
      @include font-limited(26);
    }
  }
}
