.Focus {
  padding-top: 5em;

  .wrapper {
    @include responsive($tabletSmall) {
      margin: 0 auto;
      max-width: get-vw(480);
    }
  }

  .headline {
    position: relative;
    margin-bottom: 40px;

    @include responsive($tabletSmall) {
      margin-bottom: get-vw(40);
    }

    .Icon.--star {
      position: absolute;
      min-width: 70px;
      max-width: 70px;
      top: 0;
      left: 100%;
      transform: translate(-80%, -80%) rotate(25deg);

      @include responsive($tabletSmall) {
        min-width: get-vw(70);
        max-width: get-vw(70);
        left: 0;
        transform: translate(-150%, -80%) rotate(25deg);
      }
    }
  }

  .wswyg--content {
    h3 {
      margin-bottom: 20px;
      @include font-limited(28);
      @include flex(flex-start, flex-start);
      gap: 0.4em;

      &:before {
        @include content();
        width: 0.7em;
        height: 0.7em;
        background: url('../imgs/star.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-top: 0.15em;
      }
    }
  }
}