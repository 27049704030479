.PageHeader{
  position: relative;
  padding: 15em 0 5em;

  .Page &{
    background-color: $nuit-ete-d1;
  }

  .container{
    z-index: 1;

    > .wrapper{
      max-width: 775px;
      margin: 0 auto;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(775);
      }

      > span{
        color: $ivoire-l1;
        display: block;
      }

      > h1{
        color: #FCFCFC;
      }
    }
  }

  > .visual__container{
    z-index: 0;
    pointer-events: none;

    &,
    &:after{
      @include cover();
    }

    &:after{
      @include content();

      .Contact &{
        background-color: rgba(#1A1A1A, 0.4);
      }
    }
  }
}
