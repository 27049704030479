.Pagination{
  margin-top: 3.5em;

  ul{
    @include flex(center, center);
    gap: 2em;    
    font-weight: 700;

    > li{
      line-height: 1;

      &:not(.current){
        color: $base;
        opacity: 0.3;
      }

      &.current{
        @include flex(center, center);
        gap: 2em;
        color: $base;

        a{
          display: block;
          margin-top: -0.25em;

          &:nth-child(1){
            .Icon.--chevron{
              transform: scaleX(-1);
            }
          }
        }
      }
    }
  }
}
