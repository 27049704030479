.Testimony__card {
  background: $brume-matinale-base;
  color: #525252;
  padding: 60px;
  width: 80vw;
  max-width: 560px;
  transform: rotate(3deg);
  transition: transform 640ms $ease;

  .author {
    font-size: 32px;
    font-weight: 700;
    @include font-limited(32);
    margin-bottom: 14px;
  }

  blockquote {
    text-align: left;
  }

  .swiper-slide:nth-child(3n + 2) & {
    background: $terre-cuite-base;
    color: $ivoire-base;
  }

  .swiper-slide:nth-child(3n + 3) & {
    background: $ivoire-base;
  }

  .swiper-slide.swiper-slide-active & {
    transform: rotate(-3deg);
  }

  .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide & {
    transform: rotate(-3deg);
  }
}