.ReservationBanner{
  clip-path: inset(0px 1em 0px round 1em 1em 1em);

  .container{
    @include flex(center, center);
    gap: 5em;
    
    @include responsive($tabletSmall, max){
      align-items: flex-start;
      flex-direction: column;
      gap: 3em;
      padding: 0 50px;
    }

    > *{
      width: 100%;
    }

    > .visual__container{
      @include imgRatio(405, 466);
      max-width: 405px;
      clip-path: inset(0px 0px 0px round 50% 50% 0% 0%);

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(405);
      }
    }

    > .wrapper{
      max-width: 680px;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(680);
      }

      .wswyg--content{
        @include font-limited(17);
        max-width: 320px;
        margin-top: 1.5em;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          max-width: get-vw(320);
        }

        a{
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}
