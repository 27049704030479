

.Accompaniment__card {
  padding: 40px;
  border-radius: 16px;
  @include flex(flex-start, space-between);
  flex-direction: column;
  padding-bottom: 0;
  transition: opacity 1000ms $ease,
              transform 1000ms $ease;

  @include responsive($tabletSmall) {
    padding: get-vw(50);
    border-radius: get-vw(16);
  }

  > * {
    width: 100%;
  }

  .row {
    @include flex(flex-start, space-between);
    gap: 50px;

    @include responsive($tabletSmall, max) {
      flex-direction: column;
      gap: 40px!important;
    }

    > * {
      flex: 1;
    }
  }

  h3 {
    margin-bottom: 10px;
    font-size: 40px;
    @include font-limited(40);

    @include responsive($tabletSmall) {
      margin-bottom: 20px;
    }
  }

  .subtitle {
    display: block;
    text-transform: none;
    font-size: 22px;
    @include font-limited(22);
    line-height: 1;
  }

  .wswyg--content {
    p {
      font-size: 17px;
      @include font-limited(17);
      line-height: 1.4;
    }
  }

  .exploration {
    color: $white;
    border-radius: 16px;
    padding: 30px;
    margin-top: 40px;
    margin-bottom: -60px;

    @include responsive($tabletSmall) {
      margin-bottom: -80px;
    }

    h4 {
      font-size: 32px;
      @include font-limited(32);

      @include responsive($tabletSmall) {
        max-width: 50%;
      }
    }

    ul {
      margin-top: 40px;
      opacity: 0.8;

      li {
        @include flex(flex-start, flex-start);
        gap: 12px;
        line-height: 1.2;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
  
  /*
  * Themes
  */

  @each $slug, $background, $color in $accompaniment-themes {
    &[data-theme="#{$slug}"]{
      background-color: $background;
      color: $color;

      .exploration {
        background: $color;
      }

      .Btn {
        border-color: $color;
        span { 
          color: $color;
        }

        @include noTouchHover() {
          background: $color;
          span {
            color: $white;
          }
        }
      }
    }
  }
}