.Textarea{
  @include responsive($tabletSmall){
    grid-column: span 2;
  }

  textarea{
    width: 100%;
    padding: 0.25em 0em;
    min-height: 8.5em;
    border-bottom: 1px solid #DDDDCE;
    transition: border-color 300ms $ease;

    &:focus{
      border-color: #051036;
    }

    &::placeholder{
      opacity: 0.5;
    }
  }
}
