.Input{
  text-align: left;
  
  > input{
    padding: 0.25em 0em;
    width: 100%;
    border-bottom: 1px solid #DDDDCE;
    transition: border-color 300ms $ease;

    &:focus{
      border-color: $nuit-ete-base;
    }

    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration {
      display: none;
    }

    &::placeholder{
      opacity: 0.5;
    }
  }
}
