.PascalineGrave{
  @include imgRatio(1361, 159.92);
  position: relative;
  margin-top: 5em;

  > svg{
    @include cover();

    path {
      fill: $nuit-ete-base;

      .Intro &{
        fill: $ivoire-base;
      }
    }
  }
}
