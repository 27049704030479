.PageHeaderSingle{
  position: relative;
  overflow: hidden;
  padding: 14em 0 0;

  .container{
    text-align: center;

    > .Breadcrumb,
    > .Categories{
      margin-bottom: 1em;
    }

    // Title
    > h1{
      width: 100%;
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(1100);
      }
    }

    // Date
    > time{
      @include font-limited(18);
      font-weight: 700;
      display: block;
      padding: 1.1em;
    }
  }

  > .visual__container{
    @include imgRatio(1440, 670);
    margin-top: 3.5em;

    @include responsive($tabletSmall, max){
      min-height: calc(var(--vh) * 50);
    }

    @include responsive($deskXXL){
      max-height: calc(var(--vh) * 50);
    }
  }
}
