.Prices{
  .container {
    h2{
      margin-bottom: 1em;
    }

    .grid{
      gap: 2em;
    }
  }
}
