.CheckboxFilter{
  position: relative;
  cursor: pointer;

  &.--selected{
    span{
      opacity: 1;

      &:after{
        transform: scaleX(1);
        transform-origin: 0% 0%;
      }
    }
  }

  span{
    @include font-limited(22);
    position: relative;
    font-weight: 700;
    line-height: 2.5;

    &:after{
      @include content();
      position: absolute;
      top: 100%; left: 0;
      height: 1px;
      width: 100%;
      background-color: $nuit-ete-base;
      transform-origin: 100% 0%;
      transform: scaleX(0);
      transition: transform 500ms $ease;
    }
  }
}
