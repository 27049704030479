.form-infos{
  @include flex(flex-end);
  flex-direction: column;
  text-align: right;
  gap: 1.5em;
  margin-top: 2.5em;

  > p{
    max-width: 20em;

    a{
      font-weight: 900;
      text-decoration: underline;
    }
  }

  > .Form__actions{
    @include responsive($tabletSmall){
      margin-left: auto;
    }

    a{
      text-decoration: underline;
    }

    > .Btn{
      margin-top: 0;
    }
  }
}