.Prestations {
  overflow: hidden;

  &.--related{
    .headline{
      margin-bottom: 0;
    }
  }

  .container {
    @include responsive($tabletSmall) {
      max-width: get-vw(1160);
    }
  }

  .headline {
    margin-bottom: 40px;

    @include responsive($tabletSmall) {
      margin-bottom: get-vw(40);
    }
  }

  h2 {
    text-align: center;
  }

  .swiper {
    .swiper-navigation{
      margin: 2em 0;

      .Icon.--arrow{
        svg > * {
          fill: $base;
        }
      }
    }

    .swiper-wrapper {
      align-items: stretch;
    }
    .swiper-slide {
      height: auto;

      .Prestation__card {
        height: 100%;
      }
    }
  }

  &__grid {
    @include flex(flex-start, space-between);
    flex-wrap: wrap;
    gap: 30px;
    margin: 0 auto;

    @include responsive($tabletSmall) {
      gap: get-vw(40);
      max-width: get-vw(1100);
    }

    > * {
      width: 100%;

      @include responsive($tabletSmall) {
        max-width: calc(50% - get-vw(20));
      }
    }
  }
}
