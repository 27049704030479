.Select{
  @include responsive($tabletSmall){
    grid-column: span 2;
  }

  .wrapper{
    position: relative;

    select{
      // padding: 1em 3.5em 1em 1em;
      padding: 0.5em 3.5em 0.5em 0em;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-bottom: 1.5px solid #DDDDCE;
    }

    > .Icon.--chevron{
      position: absolute;
      top: 50%; right: 0;
      transform: translate(0, -50%);
      pointer-events: none;

      @include svg-limited(10);
    }
  }
}
