

.Prestation__card {
  position: relative;
  border-radius: 16px;
  background: rgba(26, 26, 26, 0.04);

  @include responsive($tabletSmall) {
    border-radius: get-vw(16);
  }

  .inner {
    @include flex(flex-start, space-between);
    gap: 40px;
    padding: 40px;
    flex-direction: column;
    color: $nuit-ete-base;
    min-height: get-vw(260);

    @include responsive($tabletSmall) {
      padding: get-vw(50);
    }
  }

  h3 {
    font-size: 24px;
    @include font-limited(32);
  }

  .duration {
    opacity: 0.7;
    display: block;
    text-transform: none;
    font-size: 16px;
    @include font-limited(24);
    line-height: 1;
    font-weight: 600;
  }

  .Macaron {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(20%, 20%);
  }
  
  /*
  * Themes
  */

  @each $slug, $background in $prestations-themes {
    &.--#{$slug} {
      .Macaron svg {
        > circle{ fill: $background; }
        g.text-label > *{ fill: $white; }
        path.arrow{ fill: $white; }
      }
    }
  }
}