.PageHeaderPrestation {
  position: relative;
  padding: 12em 0 5em;
  background: $ivoire-l1;

  .--relaxation & {
    background: $brume-matinale-base;
  }

  .--reflexologie & {
    background: $rosee-matinale-base;
    color: $terre-cuite-base;
  }

  .container {
    z-index: 999;
  }

  h1 {
    margin-bottom: get-vw(30);
    max-width: space(13);
    line-height: 1;
  }

  .subtitle, .duration {
    max-width: space(6);
    margin: 0 auto;
    text-transform: none;
    @include font-limited(30);
    line-height: 1;
  }

  .duration {
    font-weight: 600;
    @include font-limited(28);
  }

  .Thematics__cards {
    &::after {
      display: none;
    }
  }
}
