.Accompaniments {
  .container > .headline {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .container > .row {
    @include flex(stretch, center);
    gap: 14px;

    @include responsive($tabletSmall, max) {
      flex-direction: column;
      gap: 104px;
    }

    &:not(.--visible){
      > *{
        opacity: 0;
        pointer-events: none;

        &:nth-child(1){
          transform: translate(-100%, 0);
        }
        
        &:nth-child(2){
          transform: translate(100%, 0);
        }
      }
    }

    > * {
      flex: 1;
    }
  }
}
