.ContactInfos{
  padding-bottom: 0;

  .container {
    @include flex(flex-start);
    gap: 3.5em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > *{
      width: 100%;
    }

    > .infos{
      display: grid;
      grid-gap: 2em;
      background-color: rgba(#1A1A1A, 0.04);
      padding: 3.5em 5.5em 3.5em 3.5em;
      max-width: 510px;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(510);
      }

      @include responsive($deskXXL){
        max-width: 35%;
      }

      > div{
        // Title
        > span{
          font: 700 24px $main-font;
          @include font-limited(32);
          margin-bottom: 0.5em;
        }

        // Content
        > .wswyg--content{
          @include font-limited(24);

          a{
            font-weight: initial;
            text-decoration: none;
          }
        }
      }
    }

    > .form{
      max-width: 790px;

      @include responsive($tabletSmall){
        max-width: get-vw(890);
        margin-left: auto;
      }

      @include responsive($deskXXL){
        max-width: 60%;
      }

      .head{
        margin-bottom: 2em;

        .wswyg--content{
          max-width: 320px;
          margin-top: 1.5em;

          @include responsiveMinMax($tabletSmall, $deskXXL){
            max-width: get-vw(320);
          }
        }
      }
    }
  }
}