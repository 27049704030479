.MainLogo{
  @include imgRatio(80, 108);
  width: 50px;
  position: relative;
  transition: width 500ms $ease;

  @include responsive($tabletSmall){
    width: get-vw(80);
  }

  @include responsive($deskXXL){
    width: 80px;
  }

  .Intro &{
    @include responsive($tabletSmall){
      width: get-vw(80);
    }

    @include responsive($deskXXL){
      width: 80px;
    }

    path{
      fill: $base;
    }
  }

  svg {
    @include cover();

    path{
      fill: $white;
      transition: fill 500ms $ease;
    }
  }
}
