.Law {
  padding: 30px 0;

  @include responsive($tabletSmall) {
    padding: get-vw(40) 0;
  }

  p {
    line-height: 1.2;
    @include font-limited(15);

    @include responsive($tabletSmall) {
      max-width: get-vw(600);
    }
  }
}
