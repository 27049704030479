.Notes {
  background: $nuit-ete-d1;
  color: $ivoire-base;

  .container {
    @include flex(flex-start, space-between);
    flex-direction: column;

    @include responsive($tabletSmall) {
      max-width: space(20);
      flex-direction: row;
      gap: space(2);
    }
  }

  .headline {
    text-align: center;

    @include responsive($tabletSmall) {
      position: sticky;
      top: 20vh;
      text-align: left;
      min-width: space(6);
    }
  }

  &__card {

    &:not(:first-child) {
      margin-top: -5%;
    }

    .inner {
      padding: 40px;
      @include flex(flex-start, space-between);
      gap: 20px;
      flex-direction: column;
      min-height: 240px;
  
      @include responsive($tabletSmall) {
        padding: get-vw(40);
        min-height: get-vw(260);
      }
    }

    p {
      font-size: 20px;
      @include font-limited(28);
      font-weight: 600;
      line-height: 1.2;
    }

    .Icon {
      align-self: flex-end;
      min-width: 20px;
      max-width: 20px;
    }

    @each $slug, $background, $color in $notes-themes {
      &.--#{$slug} .inner {
        background :$background;
        
        p {
          color: $color;
        }

        .Icon svg > * {
          fill: $color;
        }
      }
    }
  }
}