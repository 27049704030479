.MeetingIframe{
  padding-top: 0;

  .wrapper{
    padding: 2em;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @include responsiveMinMax($tabletSmall, $deskXXL){
      max-width: get-vw(1200);
    }

    > *{
      width: 100% !important;
    }
  }
}
