.Footer {
   background: $ivoire-l1;
   padding: 5em 0 2.5em;

   .primary{
      @include flex(flex-start, space-between);
      flex-wrap: wrap;
      gap: 2em;

      @include responsive($tabletSmall, max){
         gap: 3.5em;
        flex-direction: column;
      }

      > h2{
         @include font-limited(40);
         max-width: 330px;

         @include responsiveMinMax($tabletSmall, $deskXXL){
           max-width: get-vw(330);
         }
      }

      > nav{
         @include responsive($tabletSmall){
         //   margin-left: get-vw(200);
         }

         ul {
            li{
               & + li{
                  margin-top: 0.75em;
               }

               .Link{
                  font-weight: 700;
               }
            }
         }
      }

      > .more{
         width: 100%;
         max-width: 400px;

         @include responsiveMinMax($tabletSmall, $deskXXL){
           max-width: get-vw(400);
         }

         .Socials{
            margin-top: 3em;
         }
      }
   }

   .secondary{
      margin-top: 8.5em;
      opacity: 0.8;

      @include responsive($tabletSmall){
        margin-top: 5em;
      }

      > ul{
         @include flex(flex-start);
         flex-wrap: wrap;
         gap: 1em;

         @include responsive($tabletSmall, max){
           flex-direction: column;
         }
         
         @include responsive($tabletSmall){
            align-items: center;
         }

         li{
            .Link{
               @include font-limited(16);
            }

            &.--copyright{
               @include responsive($tabletSmall){
                 margin-left: auto;
               }
            }
         }
      }
   }
}
