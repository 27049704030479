.Journal {
  overflow: hidden;

  .swiper{
    width: 100%;
    margin-top: 2em;
    
    @include responsive($tabletSmall){
      max-width: get-vw(1140);
      margin-left: auto;
      margin-right: auto;
      margin-top: 7.5em;
    }

    @include responsive($deskXXL){
      max-width: 1140px;
    }

    .swiper-wrapper{
      @include responsive($tabletSmall){
        @include flex(center, center);
        gap: 3.5em;
      }

      .swiper-slide{
        &:nth-child(2){
          @include responsive($tabletSmall){
            margin-top: -5em;
          }
        }

        @include responsive($tabletSmall){
          width: 100%;
          flex: 1 0 calc(33.33333% - 1em);
          max-width: calc(33.33333% - 1em);
        }

        @include responsive($tabletSmall, max){
          max-width: 330px
        }
      }
    }
  }
}