.Testimonies {
  color: $ivoire-base;
  background: $nuit-ete-d1;
  overflow: hidden;
  margin-bottom: 1.2em;

  &:not(.--visible) {
    .swiper{
      .swiper-slide{
        opacity: 0;
        transform: translate(100%, 0);
        pointer-events: none;
      }
    }
  }

  .headline {
    margin-bottom: 20px!important;
  }

  .swiper{
    .swiper-slide {
      width: auto;
      transition: opacity 1000ms $ease,
                  transform 1000ms $ease;

      @for $i from 1 through 15 {
        &:nth-child(#{$i}) {
          transition-delay: 100ms + ($i * 50);
        }
      }
    }
  }
}
