/* -----
Misc
----- */

.--hidden {
  display: none !important;
}

.--centered {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.--left {
  margin-right: auto;
  text-align: left;
}

.--right{
  margin-left: auto;
  text-align: right;
}

.--sr-only {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    left: -9999px;
    top: -9999px;
}

.--no-br br {
  content: '';
  width: 9px;
  height: 18px;
  &:before {
    content: ' '
  }
}

.--mobile-swiper{
  @include responsive($tabletSmall){
    .swiper-wrapper{
      transform: none !important;
      cursor: initial !important;
    }

    .swiper-slide{
      margin: 0 !important;
    }
  }
}

.--no-sb{
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
}

.--oh{
  overflow: hidden;
}

.--pr{
  position: relative;
}

/* -----
Lazyload
----- */

html:not(.no-js) .lazyload,
html:not(.no-js) .lazyloading {
  opacity: 0;
}

html.no-js img.sr-only {
  display:block;
  top:0; left:0;
  width:100%;
  height:100%;
  clip:inherit;
  object-fit:cover;
}

.lazyloaded {
  position: relative;
  opacity: 1;
  transition: opacity 400ms linear 400ms;
}

/* -----
Prllx
----- */

*[data-prllxfrom] {
  will-change:transform;

  &[data-mobile-fix] {
    @include responsive($tabletSmall, max) {
      transform: none!important;
    }
  }
}

// Devices
.--only-desktop{
  @include responsive($tabletSmall, max){
    display: none;
  }
}

.--only-mobile{
  @include responsive($tabletSmall){
    display: none;
  }
}

// Border radius
.--br-8{ border-radius: 8px; }
.--br-10{ border-radius: 10px; }
.--br-12{ border-radius: 12px; }
.--br-16{ border-radius: 16px; }
.--br-20{ border-radius: 20px; }
.--br-30{ border-radius: 30px; }

.--bg-old-lace{ background-color: #FAF7E6; }
.--bg-ivoire-base{ background-color: $ivoire-base; }
.--bg-sauge-base{ background-color: $sauge-base; }
.--bg-peche{ background-color: $peche; }
.--bg-n-ete{ background-color: $nuit-ete-base; }

.--c-white{ color: $white; }
.--c-ivoire-l{ color: $ivoire-l1; }
.--c-lotion-matinale{ color: $lotion-matinale; }
.--c-n-ete-base{ color: $nuit-ete-base; }
.--c-n-ete-d1{ color: $nuit-ete-d1; }
.--c-ombre-orageuse{ color: $ombre-orageuse; }

.--align-center{
  @include flex(center, center);
  flex-direction: column;
}

.--text-center{
  text-align: center;
}