.About {
  overflow: hidden;
  padding-bottom: 50vw;

  @include responsive($tabletSmall) {
    padding-bottom: space(4);
  }

  .wrapper {
    position: relative;
    z-index: 10;
    @include responsive($tabletSmall) {
      margin-left: space(10);
    }
  }

  h2 {
    margin-bottom: 40px;
  }

  .wswyg--content {
    @include responsive($tabletSmall) {
      max-width: 340px;
    }
  }

  &__gallery {
    @include cover();
    pointer-events: none;
    z-index: 0;

    > * {
      position: absolute;
      @include flex(flex-end, flex-start);
      flex-wrap: wrap;
      gap: 15px;
      min-width: 53%;

      @include responsive($tabletSmall) {
        min-width: 45%;
      }

      &.left {
        bottom: -5%;
        left: 0;

        @include responsive($tabletSmall) {
          bottom: initial;
          top: 50%;
          transform: translate(-8%, -50%);
        }

        .visual__container {
          
          &:nth-child(1) {
            aspect-ratio: 200/177;
            margin-right: 60%;
            margin-bottom: -15%;
          }
          
          &:nth-child(2) {
            aspect-ratio: 230/160;
          }
          
          &:nth-child(3) {
            aspect-ratio: 190/220;
          }
          
          &:nth-child(4) {
            aspect-ratio: 146/162;
            max-width: 24%;
          }
          
          &:nth-child(5) {
            min-width: 60%;
            aspect-ratio: 406/315;
            align-self: flex-start;
          }
          
          &:nth-child(6) {
            aspect-ratio: 155/230;
            align-self: flex-start;
            max-width: 25%;
          }

        }
      }

      &.right {
        width: 35%;
        bottom: 0;
        right: 0;
        justify-content: flex-end;
        align-items: flex-end;
        transform: translate(8%, 8%);

        .visual__container {
          max-width: calc(25% - 30px / 3);

          &:nth-child(1) {
            aspect-ratio: 144/200;
            margin-left: 20%;
          }
          
          &:nth-child(2) {
            aspect-ratio: 300/325;
            min-width: 45%;
          }
          
          &:nth-child(3) {
            aspect-ratio: 200/160;
            align-self: flex-start;
            min-width: 30%;
          }
          
          &:nth-child(4) {
            aspect-ratio: 302/390;
            min-width: 45%;
          }
          
          &:nth-child(5) {
            aspect-ratio: 370/255;
            min-width: 50%;
            margin-right: auto;
            margin-top: -34%;
            margin-left: 2%;
          }

        }
      }
    }

    .visual__container {
      background: $base;
      border-radius: 10px;
      width: 100%;
      max-width: calc(33.333% - 30px / 3);
    }
  }
}
