h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $main-font;
	font-weight: 700;
	line-height: 0.9;

	&.--uppercase{
		text-transform: uppercase;
	}
}

h1 {
	font: 700 50px $main-font;
	@include font-limited(80);
	text-align: center;
	line-height: 0.75;
}

h2 {
	font-size: 42px;
	@include font-limited(60);

	.Page &,
	&.--mid{
		font-size: 24px;
		@include font-limited(40);
	}

	&.--small{
		font-size: 24px;
		@include font-limited(28);
	}
}

h3{
	font: 700 22px $main-font;
	@include font-limited(40);
	line-height: 0.9;
}

h4{
	font: 700 20px $main-font;
	@include font-limited(36);
	line-height: 0.9;
}

h5{
	font: 700 18px $main-font;
	@include font-limited(34);
	line-height: 0.9;
}

h6{
	font: 700 16px $main-font;
	@include font-limited(30);
	line-height: 0.9;
}

strong {
	font-weight: 700;
}

del{
	text-decoration: line-through;
}

p {
	&.no__results {
		text-align: center;
		opacity: 0.2;
	}
}

.subtitle{
	font: 700 16px $main-font;
	@include font-limited(16);
	text-transform: uppercase;
}

.--x-small{
	font-size: 12px;
	@include font-limited(12);
}

.wswyg--content {
	text-align: inherit;

	* {
		color: inherit;
	}

	> * + * {
		margin-top: 1em;
	}

	h3 {
	  margin-bottom: 40px;
	}

	ul,
	ol {
		padding-left: 1em;

		code {
			margin-top: 1em;
			margin-bottom: 1em;
		}
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style-type: decimal;
	}

	figure {
		margin: 1em auto;
		overflow: hidden;

		@include responsive($tabletSmall) {
			margin: space(1) auto;
		}

		&:last-child {
			margin-bottom: 0;
		}

		img {
			display: block;
		}
	}

	blockquote {
		padding-left: 1em;
		border-left: 2px solid $base;

		p {
			@include font-limited(28);
			color: $base;
			line-height: 1.4;
		}
	}

	a {
		font-weight: 700;
		text-decoration: underline;
		// color: $links-color;
	}

	table {
		font-size: 0.75em;

		td,
		th {
			padding: 1em;
			border: 1px solid rgba($grey-l, 0.25);

			* {
				font: inherit;
			}

			strong {
				font-weight: 700;
			}
		}
	}

	.plyr__container{
		position: relative;
		min-height: 400px;
		border-radius: 30px;

		@include responsive($tabletSmall, max){
			margin: 2em 0;
		}
    
		@include responsive($tabletSmall){
			height: get-vw(500);
			margin: get-vw(50) 0;
		}

		@include responsive($deskXXL){
			height: 500px;
			margin: 50px 0;
		}
	}

	.plyr__control.plyr__control--overlaid{
		@include flex(center, center);
		background: none;
		opacity: 1;
		height: 80px;
		width: 80px;

		@include responsiveMinMax($tabletSmall, $deskXXL){
			height: get-vw(80);
			width: get-vw(80);
		}

		svg{
			width: 80%;
			height: 80%;
			fill: $white;
		}
	}
}
