.Thematics {
  position: relative;
  min-height: 100dvh;

  .container {
    z-index: 10;
  }

  .wrapper {
    @include responsive($tabletSmall) {
      margin-left: space(10);
      padding-right: space(1);
    }

    h2 {
      font-size: 58px;
	    @include font-limited(72);
    }
  }

  &__cards {
    @include cover();
    overflow: hidden;

    &:after {
      @include content();
      @include cover();
      z-index: 200;
      background: linear-gradient(0deg, rgba($ivoire-base, 1) 0%, rgba($ivoire-base, 0) 30%);
    }
  }

  &__card {
    position: absolute;
    bottom: 100%;
    left: 0;
    @include flex(center, center, inline-flex);
    flex-direction: column;
    padding: 40px;
    background: $base;
    color: $white;
    border-radius: 10px;
    min-height: 160px;
    min-width: 220px;

    span {
      font: 700 24px $main-font;
      @include font-limited(24);
    }

    &.--love { background: $terre-cuite-base; }
    &.--energy { background: $bleu-givre-base; }
    &.--sun { background: $gold; }
    &.--balance { background: $sauge-base; }
    &.--mental { background: $rosee-matinale; color: $terre-cuite-base; }
    &.--focus { background: $peche; }
    &.--harmony { background: $brume-matinale-base; color:$nuit-ete-base; }
  }
}