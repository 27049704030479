.Close{
  @include flex(center, center);
  position: relative;
  height: get-vw(70);
  width: get-vw(70);
  border-radius: 50%;
  background: rgba($white, 0.35);
  pointer-events: all;
  z-index: 1;
  transition: transform 500ms $ease;

  @include responsive($menuBreakpoint, max){
    min-height: 44px;
    min-width: 44px;
  }

  @include responsive($deskXL){
    height: 70px;
    width: 70px;
  }

  @include noTouchHover() {
    transform: scale(0.9) rotate(-180deg);
  }

  &:before,
  &:after{
    @include content();
    height: 1em;
    width: 0.1em;
    position: absolute;
    background: $white;
  }

  &:before{
    transform: rotate(-45deg);
  }

  &:after{
    transform: rotate(45deg);
  }
}