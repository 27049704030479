// Only for Single Prestation
.PrestationDetails{
  padding-bottom: 0;

  .container{
    @include flex(flex-start);
    gap: 5em;
    
    @include responsive($tabletSmall, max){
      flex-direction: column;
      gap: 2.5em;
    }
    
    @include responsive($tabletSmall){
      max-width: get-vw(1100);
      padding-bottom: 10em;
    }

    > *{
      width: 100%;
    }

    > .visual__scroll{
      @include imgRatio(320, 435);
      position: relative;
      max-width: 320px;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(320);
      }

      .visual__container{
        @include cover();
        clip-path: inset(0 0 0 round 1em 1em 1em);
      }
    }

    > .wrapper{
      display: grid;
      grid-gap: 3em;
      max-width: 560px;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(560);
      }

      .item{
        @include flex(flex-start);
        flex-direction: column;
        width: 100%;

        h2, h3, h4, h5, h6{
          margin-bottom: 0.75em;
        }

        p, ul, ol{
          max-width: 360px;

          @include responsiveMinMax($tabletSmall, $deskXXL){
            max-width: get-vw(360);
          }
        }

        .application {
          margin-top: 2em;
          padding: 2em;
          background: rgba(26, 26, 26, 0.04);

          h4 {
            margin-bottom: 0.5em;
            @include font-limited(32);
          }
        }
      }
    }
  }

  > .placeholder.--second{
    @include imgRatio(1440, 700);

    @include responsive($tabletSmall, max){
      display: none;
    }
  }
}
