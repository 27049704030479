.Btn {
  cursor: pointer;
  @include font-limited(17);
  font-weight: 700;
  display: inline-block;
  color: $white;
  padding: 0.82em 2.5em 0.94em;
  border-radius: 50%;
  border: 1px solid $nuit-ete-base;
  margin-top: 2em;
  
  &, 
  > span{
    body:not(.--animating) &{
      transition: border-color 500ms $ease,
                  background-color 500ms $ease,
                  color 500ms $ease,
                  transform 500ms $ease;
    }
  }

  &.--hidden{
    display: none;
  }

  @media (pointer: fine) {
    &:hover,
    &:focus{
      transform: rotate(-2.5deg) scale(0.95);
      border-color: $nuit-ete-base;
      
      > span{
        transform: rotate(2.5deg);
        color: $ivoire-l1;
      }
    }

    &:hover{
      background-color: $nuit-ete-base;
    }

    &:focus{
      background-color: $nuit-ete-d1;
    }
  }

  /*
  * Themes
  */

  @each $slug, $background, $color, $color-hover, $focus in $buttons-themes {
    &.--#{$slug}{
      border-color: $background;

      @media (pointer: fine) {
        &:hover,
        &:focus{
          > span{
            color: $color-hover;
          }
        }

        &:hover{
          background-color: $background;
        }

        &:focus{
          background-color: $focus;
        }

        > span{
          // color: $color-hover;
        }
      }

      > span{
        color: $color;
      }
    }
  }

  > span{
    color: $nuit-ete-base;
  }
}
