// Only for Archive Prestations
.PrestationsDetails{
  padding-top: 0;

  > .visual__scroll{
    @include imgRatio(1440, 700);
    position: relative;

    .visual__container{
      @include cover();
    }
  }

  > .container{
    @include responsive($tabletSmall, max){
      margin-top: 5em;
    }
  
    @include responsive($tabletSmall) {
      max-width: get-vw(1160);
    }

    .wrapper{
      @include flex(flex-start);
      gap: 10em;

      @include responsive($tabletSmall, max){
        flex-direction: column;
      }

      .placeholder.--second{
        @include imgRatio(340, 500);
        width: 100%;
        max-width: 340px;

        @include responsive($tabletSmall, max){
          display: none;
        }

        @include responsiveMinMax($tabletSmall, $deskXXL){
          max-width: get-vw(340);
        }
      }

      .wswyg--content{
        max-width: 400px;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          max-width: get-vw(400);
        }
      }
    }
  }
}
