.Macaron{
  @include imgRatio(108, 108);
  @include svg-limited(100);
  position: relative;
  pointer-events: none;
  transition: transform 500ms $ease;

  button:hover &,
  a:hover &{
    transform: scale(0.85);

    g.text-label{
      transform: rotate(-90deg);
    }

    path.arrow{
      transform: rotate(-10deg) scale(1.35);
    }
  }
  
  > svg{
    @include cover();

    g.text-label,
    path.arrow{
      transform-origin: 50% 50%;
      transition: transform 500ms $ease;
    }
  }
  
  &.--article{
    circle{ fill: $base; }
    g.text-label > *{ fill: $white; }
    path.arrow{ fill: $white; }

    /*
    * Themes
    */

    @each $slug, $background, $color, $background-macaron, $color-macaron in $categories-themes {
      .ArticleCard.--#{$slug} &{
        circle{ fill: $background-macaron; }
        g.text-label > *, path.arrow{ fill: lighten($color-macaron, 10); }
      }
    }
  }

  &.--detail{
    circle{ fill: $base; }
    g.text-label > *{ fill: $white; }
    path.arrow{ fill: $white; }
  }

  &.--ebook,
  &.--newsletter{
    circle{ fill: $nuit-ete-base; }
    g.text-label > *{ fill: $white; }
    path.arrow{ fill: $white; }
  }
}
