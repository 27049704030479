.ContactPrestationsDetails{
  .container{
    @include flex(flex-start, center);
    gap: 2.5em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > div{
      width: 100%;
      max-width: 430px;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(430);
      }

      > h2{
        margin-bottom: 0.5em;
      }
    }
  }
}
