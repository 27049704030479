.Form{
  text-align: left;

  .Contact &{
    .grid,
    .row{
      display: grid;
      grid-gap: 2em 1.5em;

      @include responsive($tabletSmall){
        grid-template-columns: repeat(2, 1fr);
      }

      > *.--full{
        @include responsive($tabletSmall){
          grid-column: span 2;
        }
      }
    }

    .form-footer{
      @include flex(center, space-between);
      width: 100%;

      @include responsive($tabletSmall, max){
        flex-direction: column;
      }

      a{
        text-decoration: underline;
      }
    }
  }

  label:not(.Checkbox){
    font: 700 14px $main-font;
    @include font-limited(14);
    display: block;
    margin-bottom: 0.75em;
    // opacity: 0.5;

    sup,
    abbr{
      color: $peche;
    }
  }

  .form-footer{
    p.--x-small{
      margin-top: 1em;
      opacity: 0.5;
      max-width: 24em;
    }

    *[disabled] {
      pointer-events: none;
    }
  }

  .form-footer,
  .Form__group{
    *[disabled]{
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .Form__group{
    position: relative;

    &.--error{
      input,
      textarea{
        color: $error-color;
        border-color: $error-color;
      }
    }

    span.--error{
      position: absolute;
      top: 100%; left: 0;

      font-size: 0.5em;
      color: $error-color;
      margin-top: 1em;
    }
  }

  .Form__output{
    &:not(:empty) {
      font: 700 14px $main-font;
      color: $valid-color;
      @include font-limited(14);
      text-align: left;
      margin-top: 2em;

      .Newsletter &{
        text-align: left;
        display: inline-block;
        padding: 1em;
        border-radius: 10px;
        background: #7da237;
        color: $white;
      }
    }
  }
}
