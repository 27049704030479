.EBookBtn{
  display: inline-flex;
  align-items: center;
  gap: 0.5em;

  position: sticky;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -20%);
  border-radius: 8px;
  padding: 0.75em 1.15em;
  background-color: $nuit-ete-d1;
  color: $ivoire-l1;
  z-index: 10;
  transition: transform 500ms $ease;

  @include noTouchHover() {
    transform: translate(-50%, -20%) scale(0.95);
  }
}