.NotFound{
  position: relative;
  @include fullHeight();
  min-height: 650px;
  overflow: hidden;

  .container{
    height: 100%;
    z-index: 1;

    h1{
      font-size: 30vw;
      font-weight: 400;
      margin-bottom: 0.15em;

      @include responsive($tabletSmall){
        @include font-vw(200);
      }
    }
  }
}