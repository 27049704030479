.Copyright {
  @include flex(center, center);
  gap: 0.5em;

  .wkn{
    margin-top: 0.35em;

    svg {
      fill: $ombre-orageuse;
    }
  }
}
