.Hero {
  @include flex(center, center);
  position: relative;
  background: $nuit-ete-d1;
  min-height: calc(var(--vh) * 100);
  padding: 120px 0 calc(120px + 80px);
  color: $ivoire-base;
  text-align: center;

  .container {
    position: static;
    z-index: 10;
  }

  h1 {
    font-size: 60px;
    @include font-limited(140);
    margin: 0 auto;
    max-width: 420px;

    @include responsive($tabletSmall) {
      max-width: 66%;
    }
  }

  .slogan {
    position: absolute;
    bottom: 40px;
    left: 50%;
    @include flex(center, center);
    gap: 15px;
    transform: translate(-50%, 0%);
    width: 100%;

    span {
      display: block;
      font: 700 24px $main-font;
      @include font-limited(24);
      line-height: 1.1;
      max-width: 260px;

      @include responsive($tabletSmall) {
        max-width: get-vw(280);
      }
    }
  }

  &__bg {
    @include cover();

    .visual__container {
      @include cover();
    }
  }
}