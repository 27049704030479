.Icon {
  position: relative;

  svg {
    @include cover();
  }

  &.--tick{
    @include imgRatio(10, 8);
    @include svg-limited(10);
  }

  &.--chevron {
    @include imgRatio(24, 24);
    @include svg-limited(24);

    svg > *{
      stroke: $base;
    }
  }

  &.--chevron-submenu{
    @include imgRatio(10, 8);
    @include svg-limited(10);
    margin-top: 0.25em;
    transition: transform 350ms $ease;

    svg > *{
      fill: $ivoire-l1;
    }
  }

  &.--arrow {
    @include imgRatio(24, 24);
    @include svg-limited(24);
  }

  &.--x,
  &.--facebook,
  &.--linkedin,
  &.--instagram,
  &.--youtube{
    @include imgRatio(24, 24);
    @include svg-limited(24);

    svg > * {
      fill: $nuit-ete-base;
    }
  }

  &.--book{
    @include imgRatio(14, 14);
    @include svg-limited(20);
  }

  &.--star {
    @include imgRatio(14, 14);
    @include svg-limited(14);
  }

  &.--love, &.--sun, &.--energy {
    @include imgRatio(45, 45);
    @include svg-limited(45);
  }

  &.--balance {
    @include imgRatio(44, 34);
    @include svg-limited(45);
  }

  &.--mental {
    @include imgRatio(46, 39);
    @include svg-limited(45);
  }

  &.--focus {
    @include imgRatio(51, 41);
    @include svg-limited(45);
  }

  &.--harmony {
    @include imgRatio(38, 42);
    @include svg-limited(45);
  }
}
