.Intro {
  @include cover(fixed);
  z-index: 2000;

  .PascalineGrave{
    width: 100%;
    max-width: 90%;

    @include responsive($tabletSmall){
      max-width: get-vw(1140);
    }

    @include responsive($deskXXL){
      max-width: 1140px;
    }
  }

  .visual__container{
    position: absolute;    
    top: 50%; left: 50%;
    // transform: translate(-50%, -50%);

    @include responsive($tabletSmall, max){
      max-width: 45%;
    }

    &:nth-child(1){
      @include imgRatio(330, 400);

      @include responsive($tabletSmall){
        max-width: get-vw(330);
      }

      @include responsive($deskXXL){
        max-width: 330px;
      }
    }
    
    &:nth-child(2){
      @include imgRatio(290, 350);

      @include responsive($tabletSmall){
        max-width: get-vw(290);
      }

      @include responsive($deskXXL){
        max-width: 290px;
      }
    }
    
    &:nth-child(3){
      @include imgRatio(320, 225);

      @include responsive($tabletSmall){
        max-width: get-vw(320);
      }

      @include responsive($deskXXL){
        max-width: 320px;
      }
    }
    
    &:nth-child(4){
      @include imgRatio(220, 290);

      @include responsive($tabletSmall){
        max-width: get-vw(220);
      }

      @include responsive($deskXXL){
        max-width: 220px;
      }
    }
    
    &:nth-child(5){
      @include imgRatio(210, 270);

      @include responsive($tabletSmall){
        max-width: get-vw(210);
      }

      @include responsive($deskXXL){
        max-width: 210px;
      }
    }
  }
}
