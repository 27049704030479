.Nav {
  position: relative;
  @include flex(center, center);
  pointer-events: all;
  gap: get-vw(160);
  // transform: translateX(5%);
  transform: translateX(-2%);
  transition: gap 420ms $ease;
  pointer-events: none;
  z-index: 100;

  @include responsive($menuBreakpoint, max) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: fixed;
    top: 0; right: 0;
    width: 100%; 
    height: 100%;
    max-width: 380px;
    background: $nuit-ete-d1;
    color: $white;
    overflow-y: scroll;
    padding: 170px 40px 80px;
    z-index: 10;
    gap: 1em;
    pointer-events: all;
    transform: translateX(0%);
  }

  .Header.--rose & {
    @include responsive($menuBreakpoint, max) {
      background: $terre-cuite-base;
    }
  }

  @include responsive(500px, 650px){
    max-width: 80%;
  }

  body:not(.--show-menu) & {
    @include responsive($menuBreakpoint, max) {
      transform: translate(100%, 0);  
    }
  }

  body:not(.--animating) &{
    @include responsive($menuBreakpoint, max){
      transition: transform 850ms $ease;
    }
  }

  > ul{
    @include flex(flex-start);
    gap: 2em;

    @include responsive($menuBreakpoint, max){
      flex-direction: column;
      gap: 1em;
    }

    @include responsive($menuBreakpoint){
      align-items: center;
      justify-content: center;
    }

    > li{
      pointer-events: all;
      &.--submenu{
        position: relative;

        &.--active{
          > .Link{
            &[type="button"]{
              .Icon.--chevron-submenu{
                transform: scaleY(-1);
              }
            }
          }
        }
      }

      > .Link{
        color: $white;

        span {
          @include responsive($menuBreakpoint, max){
            font-size: 32px;
            line-height: 1.2;
          }
        }

        body.--show-submenu .Header:not(.--scroll) & {
          @include responsive($menuBreakpoint){
            color: $white!important;

            &[type="button"]{
              .Icon.--chevron-submenu{
                svg > *{
                  fill: $white!important;
                }
              }
            }
          
            span{
              background-image: linear-gradient($white, $white)!important;
            }
          }
        }

        body:not(.--show-menu) .Header.--scroll:not(.--hide) &,
        body:not(.--show-menu) .Header.--dark & {
          @include responsive($menuBreakpoint){
            color: $nuit-ete-base;

            &[type="button"]{
              .Icon.--chevron-submenu{
                svg > *{
                  fill: $nuit-ete-base;
                }
              }
            }
          
            span{
              background-image: linear-gradient($nuit-ete-base, $nuit-ete-base);
            }
          }
        }

        body:not(.--show-menu) .Header.--rose.--scroll:not(.--hide) &,
        body:not(.--show-menu) .Header.--rose & {
          @include responsive($menuBreakpoint){
            color: $terre-cuite-base;

            &[type="button"]{
              .Icon.--chevron-submenu{
                svg > *{
                  fill: $terre-cuite-base;
                }
              }
            }
          
            span{
              background-image: linear-gradient($terre-cuite-base, $terre-cuite-base);
            }
          }
        }

        &[type="button"] {
          display: inline-flex;
          align-items: center;
          gap: 0.5em;

          .Icon.--chevron-submenu {
            svg > *{
              fill: $white;
            }
          }
        }
    
        span {
          background-image: linear-gradient($white, $white);
        }
      }
    }

    .Link {
      font-weight: 700;
    }
  }

  .Btn {
    padding: 0.6em 1.6em 0.6em;

    @include responsive($menuBreakpoint){
      margin-top: 0;
    }

    body:not(.--show-menu):not(.--show-submenu) .Header:not(.--dark).--rose & {
      border-color: $terre-cuite-base;

      @media (pointer: fine) {
        &:hover,
        &:focus{
          border-color: $terre-cuite-base;

          > span{
            color: $ivoire-l1;
          }
        }

        &:hover{
          background-color: $terre-cuite-base;
        }

        &:focus{
          background-color: $terre-cuite-base;
        }
      }

      > span{
        color: $terre-cuite-base;
      }
    }
    
    .Header:not(.--dark):not(.--scroll) &,
    body.--show-submenu .Header:not(.--scroll) &,
    body.--show-menu & {
      border-color: $white;

      @media (pointer: fine) {
        &:hover,
        &:focus{
          border-color: $nuit-ete-base;

          > span{
            color: $ivoire-l1;
          }
        }

        &:hover{
          background-color: $nuit-ete-base;
        }

        &:focus{
          background-color: $nuit-ete-d1;
        }
      }

      > span{
        color: $white;
      }
    }
  }
}
