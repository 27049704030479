.NavToggle {
  @include flex(center, center);
  gap: 0.25em;
  cursor: pointer;
  position: relative;
  width: get-vw(25);
  height: get-vw(17);
  z-index: 10;
  transition: opacity 500ms $ease;
  
  @include responsive($tabletSmall, max){
    min-width: 25px;
    min-height: 17px;
  }

  @include responsive($menuBreakpoint) {
    display: none;
  }

  body:not(.--animating) &{
    pointer-events: all;
  }

  // body.--show-submenu &{
  //   opacity: 0;
  //   pointer-events: none;
  // }

  &:before,
  &:after{
    @include content();
  }

  &:before,
  &:after,
  > span{
    position: absolute;
    top: 50%; left: 50%;
    height: 0.15em;
    width: 100%;
    background-color: $white;
    border-radius: 20em;
    transition: background-color 450ms $ease,
                transform 450ms $ease;

    body:not(.--show-menu) .Header.--scroll:not(.--hide) &,
    body:not(.--show-menu) .Header.--dark &{
      background-color: $black;
    }

    body:not(.--show-menu) .Header.--rose &{
      background-color: $terre-cuite-base;
    }
  }

  &:before{
    transform: translate(-50%, -0.5em);

    body.--show-menu &{
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  > span{
    transform: translate(-50%, -50%);
    transform-origin: 0% 0%;
    
    body.--show-menu &{
      transform: translate(-50%, -50%) scaleX(0);
      transform-origin: 100% 0%;
    }
  }

  &:after{
    transform: translate(-50%, 0.38em);

    body.--show-menu &{
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
