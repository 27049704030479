/* -----
Paths
----- */

$base-images: "../images/";
$base-fonts: "../fonts/";

:root{
  --vh: 100vh;
  --vw: 100vw;
}

/* -----
Colors
----- */
$white: #ffffff;
$black: #000000;
$grey: #999999;
$grey-l: #eeeeee;
$gold: #af904d;
$base: #1a1a1a;
$base-d: #141414;
$links-color: $gold;
$error-color: #dc3838;
$valid-color: #5da854;

/* -----
Custom Colors
----- */
$brume-matinale: #BBD5D1;

$dark-blue: #051036;
$peche: #CF9987;

$ivoire-base: #FAF7E6;
$ivoire-l1: #FDFBEC;

$brume-matinale-base: #BBD5D1; 

$sauge-base: #88917C;

$rosee-matinale-base: #F1D5CC;
$rosee-matinale: #F1D5CC;

$lotion-matinale: #FCFCFC;

$nuit-ete-base: #45586F;
$nuit-ete-d1: #2B3E55;

$terre-cuite-base : #A67672;
$terre-cuite-d1: #93615D;

$bleu-givre-base: #779DA7;

$gold: #BC9F41;

$ombre-orageuse: #778287;

$pistache-fraiche: #E0E9D5;

/* -----
Colors Themes
----- */

// Themes in : _btn.scss
$buttons-themes: 
  // Slug            // Background        // Color          // Color Hover    // Background Pressed
  'red'              $terre-cuite-d1      $terre-cuite-d1   $ivoire-l1        #845754,
  'ivoire'           $ivoire-base         $ivoire-base      $nuit-ete-base    $ivoire-base,
;

$links-themes: 
  // Slug            // Color
  'white'            $white,
  'nuit-ete'         $nuit-ete-base,
  'ombre-orageuse'   $ombre-orageuse
;

// Themes in : _price-card.scss
$prices-themes: 
  // Slug            // Background     // Color
  'green'            $brume-matinale   $nuit-ete-d1,
  'red'              $rosee-matinale   $terre-cuite-d1,
  'white'            $lotion-matinale  $ombre-orageuse,
  'sauge'            $pistache-fraiche #686F5E,
;

// Themes in : _price-card.scss
$categories-themes: 
  // Slug            // Background     // Color           // Background Macaron    // Color Macaron
  'red'              $rosee-matinale   $terre-cuite-d1    $terre-cuite-d1          $rosee-matinale,
  'blue'             $brume-matinale   $nuit-ete-d1       $nuit-ete-d1             $brume-matinale,
  'green'            $sauge-base       $ivoire-base       $sauge-base              $ivoire-base,
  'yellow'           #FBF7E6         #BD9F41          #BD9F41                $white,
  'green_sapin'      #E0E9D5         #435D51          #435D51                $white,
  'grey'             #778288         $white             #778288                $white,
  'peach'            #CF9987         $white             #CF9987                $white,
;

// Themes in : _prestation-card.scss
$prestations-themes: 
  // Slug            // Background
  'rose'             $peche,
  'yellow'           $gold,
  'blue'             $bleu-givre-base,
  'green'            $sauge-base,
  'grey'             $ombre-orageuse
;

// Themes in : _BlockNotes.scss
$notes-themes: 
// Slug            // Background           // Color
'blue'             $brume-matinale-base    $nuit-ete-base,
'yellow'           $ivoire-base            $gold,
'rose'             $rosee-matinale-base    $terre-cuite-d1,
'grey'             $lotion-matinale        $bleu-givre-base,
'green'            $sauge-base             $ivoire-l1
;

// Themes in : _accompaniment-themes.scss
$accompaniment-themes: 
  // Slug                   // Background          // Color 
  'reflexologie'            $rosee-matinale-base   $terre-cuite-d1,
  'relaxation'              $brume-matinale-base   $nuit-ete-d1
;


/* -----
Fonts
----- */

$main-font: 'Neuton', sans-serif;

/* -----
Grid
----- */

$base-vw: 1440;
$base-grid: 24;
$browser-context: 20;

/* -----
CSS Easings
----- */

$transitionDuration : 600ms;
$ease: cubic-bezier(0.36, 0.33, 0, 1);
$snap: cubic-bezier(0,1,.5,1);
$easeOutCubic: cubic-bezier(.215,.61,.355,1);
$easeInOutCubic: cubic-bezier(.645,.045,.355,1);
$easeInCirc: cubic-bezier(.6,.04,.98,.335);
$easeOutCirc: cubic-bezier(.075,.82,.165,1);
$easeInOutCirc: cubic-bezier(.785,.135,.15,.86);
$easeInExpo: cubic-bezier(.95,.05,.795,.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1,0,0,1);
$easeInQuad: cubic-bezier(.55,.085,.68,.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInQuart: cubic-bezier(.895,.03,.685,.22);
$easeOutQuart: cubic-bezier(.165,.84,.44,1);
$easeInOutQuart: cubic-bezier(.77,0,.175,1);
$easeInQuint: cubic-bezier(.755,.05,.855,.06);
$easeOutQuint: cubic-bezier(.23,1,.32,1);
$easeInOutQuint: cubic-bezier(.86,0,.07,1);
$easeInSine: cubic-bezier(.47,0,.745,.715);
$easeOutSine: cubic-bezier(.39,.575,.565,1);
$easeInOutSine: cubic-bezier(.445,.05,.55,.95);
$easeInBack: cubic-bezier(.6,-.28,.735,.045);
$easeOutBack: cubic-bezier(.175, .885,.32,1.275);
$easeInOutBack: cubic-bezier(.68,-.55,.265,1.55);
$easeSwiper: cubic-bezier(0.36, 0.33, 0, 1);


// GSAP Easings
$Power0EaseNone: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$Power1EaseIn: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$Power1EaseOut: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$Power1EaseInOut: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$Power2EaseIn: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$Power2EaseOut: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$Power2EaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$Power3EaseIn: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$Power3EaseOut: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$Power3EaseInOut: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$Power4EaseIn: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$Power4EaseOut: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$Power4EaseInOut: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$CircEaseIn: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$CircEaseOut: cubic-bezier(0.075, 0.820, 0.165, 1.000); // wip
$CircEaseInOut: cubic-bezier(0.785, 0.135, 0.150, 0.860); // wip
$ExpoEaseIn: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ExpoEaseOut: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ExpoEaseInOut: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$SineEaseIn: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$SineEaseOut: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$SineEaseInOut: cubic-bezier(0.445, 0.050, 0.550, 0.950);

/* -----
Media Queries
----- */

$mobileSmall: 330px;
$mobile: 550px;
$tabletSmall: 768px;
$tablet: 992px;
$desk: 1200px;
$deskXL: 1460px;
$deskXXL: 1680px;
