.Discover {
  clip-path: inset(0px 1em 0px round 1em 1em 1em);
  background: $rosee-matinale;
  color: $terre-cuite-base;
  margin-bottom: 1em;

  @include responsive($tabletSmall, max){
    padding-bottom: 240px;
  }

  .--reflexologie & {
    background: $brume-matinale;
    color: $nuit-ete-d1;
  }

  .--relaxation & {
    .Btn {
      border: 1px solid $terre-cuite-base;
  
      > span {
        color: $terre-cuite-base
      }
  
      &:hover{
        background-color: $terre-cuite-base;
        color: $white;
  
        > span {
          color: $white
        }
      }
    }  
  }

  .container {
    max-width: 90%;
    @include responsive($tabletSmall){
      max-width: get-vw(1100);
    }

    .headline {
      margin-bottom: 30px;
      @include responsive($tabletSmall){
        margin-bottom: get-vw(40);
      }

      h2 {
        @include responsive($tabletSmall) {
          max-width: get-vw(700);
          margin: auto;
        }
      }
    }
  }

  .content {
    @include responsive($tabletSmall){
      max-width: get-vw(500);
      margin: 0 auto;
    }

    p {
      text-align: left;
    }
  }

  &__gallery {
    position: absolute;
    bottom: 0;
    width: 100%;
    @include flex(flex-end, center);
    transform: translateY(20%);
    pointer-events: none;

    @include responsive($tabletSmall){
      @include cover();
      align-items: flex-end;
      justify-content: space-between;
      transform: none;
    }

    &__visual {
      &:nth-child(2) {
        @include responsive($tabletSmall){
          align-self: flex-start;
        }

        .visual__container {
          transform: rotate(-3deg) translate(-5%, -5%);

          @include responsive($tabletSmall){
            transform: rotate(-3deg) translate(-5%, -20%);
          }
        }
      }
    }

    .visual__container {
      width: 170px;
      @include imgRatio(300, 400, $base);
      transform: rotate(3deg) translate(5%, 5%);

      @include responsive($tabletSmall){
        width: get-vw(300);
        transform: rotate(3deg) translate(15%, 20%);
      }
    }
  }
}