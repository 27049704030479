.Categories{
  ul{
    @include flex(center, center);
    flex-wrap: wrap;

    li {
      padding: 0.5em 1em 0.7em 1em;
      background-color: $base;
      color: $white;
      border-radius: 4px;
      text-transform: initial;

      /*
      * Themes
      */

      @each $slug, $background, $color in $categories-themes {
        &.--#{$slug}{
          background-color: $background;
          color: $color;
        }
      }
    }
  }
}
