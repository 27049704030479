.Office {
  @include responsive($tabletSmall) {
    padding: 120px 0;
  }

  .container {
    @include responsive($tabletSmall) {
      max-width: space(21);
    }
  }

  .headline {
    @include flex(flex-start, space-between);

    @include responsive($tabletSmall, max) {
      flex-direction: column;
      gap: 20px;
    }

    .wswyg--content {
      @include responsive($tabletSmall) {
        width: 60%;
      }
    }

    p {
      @include responsive($tabletSmall) {
        max-width: space(6);
      }
    }
  }

  &__visual {

    .wrapper {
      position: relative;
      @include imgRatio(1130, 600);
    }
    .visual__container {
      @include cover();
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
