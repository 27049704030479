$tarteaucitron-bgcolor: $black;

#tarteaucitronRoot {
  transition: opacity 1500ms ease;

  body.--animating &{
    opacity: 0;
    pointer-events: none;
  }

  &.tarteaucitronBeforeVisible {
    #tarteaucitronAlertBig {
      @include responsive(520px) {
        display: flex!important;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }

  #tarteaucitronBack {
    background: $black !important;
    opacity: 0.9!important;
  }

  #tarteaucitronAlertBig {
    box-sizing: border-box !important;
    background: $white !important;
    color: $black !important;
    padding: 40px !important;
    left: 8px !important;
    right: 8px !important;
    bottom: 8px !important;
    border-radius: 30px;
    width: auto !important;
    margin: 0 !important;
    position: relative;
    
    @include responsive(520px) {
      width: 100% !important;
      max-width: 730px;
      left: 50% !important;
      // right: 20px !important;
      bottom: 20px !important;
      margin: 0;
      transform: translate(-50%, 0);
    }

    .tarteaucitronCheck,
    .tarteaucitronCross{
      display: none !important;
    }

    // &:before{
    //   content: 'Cookies';
    //   display: inline-block;
    //   position: absolute;
    //   bottom: 100%; left: 0;
    //   font: 900 14px $GT-Ultra;
    //   text-transform: uppercase;
    //   border-radius: 8px;
    //   background: $black;
    //   color: $white;
    //   padding: 0.55em 0.85em;
    //   text-align: center;
    //   // transform: translate(-30%, 50%) rotate(-10deg);

    //   @include responsive(520px, max){
    //     display: none;
    //   }
    // }

    #tarteaucitronDisclaimerAlert {
      margin-right: auto!important;
      font: 700 22px $main-font;
      line-height: 1.6;
      color: $base;
      display: block;
      text-align: center;
      // margin-bottom: 1.5em;
      // min-width: 100%;
      // max-width: 100%;
      padding: 0;

      max-width: 25em;
      min-width: auto;
      margin: 0 auto 0.5em;
    }

    > button {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      gap: 0.7em;
      border-radius: 20em;
      padding: 0.82em 2.5em 0.94em !important;
      background: $white !important;
      color: $base !important;
      // margin-top: 10px;
      margin: 0.5em;
      transition: transform 450ms $ease,
                  box-shadow 450ms $ease;

      font: 700 0.77em $main-font !important;
      border-radius: 50%;
      border: 1px solid $nuit-ete-base;

      &:hover{
        transform: scale(0.95);
      }

      &.tarteaucitronAllow{
        background: $white !important;
      }

      &.tarteaucitronDeny{
        background: $white !important;
      }

      @media (pointer: fine) {
        &:hover,
        &:focus{
          transform: rotate(-2.5deg) scale(0.95);
          border-color: $nuit-ete-base;
      
          > span{
            transform: rotate(2.5deg);
            color: $ivoire-l1;
          }
        }

        &:hover{
          background-color: $nuit-ete-base;
        }

        &:focus{
          background-color: $nuit-ete-d1;
        }
      }
    }
  }

  #tarteaucitronClosePanel {
    background: $tarteaucitron-bgcolor!important;
    border-color: $tarteaucitron-bgcolor!important;
  }

  #tarteaucitronServices {
    box-shadow: none!important;

    .tarteaucitronMainLine,
    .tarteaucitronTitle button {
      background: $tarteaucitron-bgcolor!important;
      border-color: $tarteaucitron-bgcolor!important;
    }

    .tarteaucitronAllow, .tarteaucitronDeny {
      margin: 0!important;
      background: $white !important;
      font-size: 9px!important;
      letter-spacing: 0.1em;
      font-weight: bold;
      margin-left: 10px!important;
      padding: 10px 20px!important;
      color: $tarteaucitron-bgcolor!important;
      text-transform: uppercase;

      &:hover {
        opacity: 1;
      }

      span {
        display: none!important;
      }
    }

    ul .tarteaucitronLine.tarteaucitronIsAllowed { border-color: $valid-color!important; }
    ul .tarteaucitronLine.tarteaucitronIsDenied { border-color: $error-color!important; }

    #tarteaucitronInfo {
      background: 0!important;
    }
  }
}
