.PageHeaderAbout {
  position: relative;
  padding: 15em 0 5em;

  @include responsive($tabletSmall) {
    padding-bottom: 0;
  }

  @include responsive($tabletSmall, max) {
    overflow: hidden;
  }

  &:after {
    
    @include responsive($tabletSmall) {
      @include content();
      position: sticky;
      bottom: 0;
      height: 10em;
      background: linear-gradient( 180deg, rgba($ivoire-base, 0) 0%, rgba($ivoire-base, 1) 100%);
      width: 100%;
    }
  }

  .container {
    @include flex(flex-start, space-between);
    flex-direction: column;
    gap: 10%;

    @include responsive($tabletSmall) {
      flex-direction: row;
    }
  }

  .headline {
    margin-bottom: 60px;

    @include responsive($tabletSmall) {
      position: sticky;
      top: 20%;
      min-width: 33%;
      max-width: 33%;
      margin-bottom: 0;
      z-index: 10;
    }

    h1 {
      text-align: left;
      @include font-limited(60);
      line-height: 0.95;
      margin-bottom: 30px;
    }

    p {
      line-height: 1.2;
      max-width: 80%;
    }
  }

  .wswyg--content {
    padding-right: 10%;

    h3 {
      @include font-limited(40);
    }

    p {
      @include font-limited(26);
    }

    .sign {
      position: relative;
      @include imgRatio(137, 22);
      width: 140px;
      margin-top: 40px;

      @include responsive($tabletSmall) {
        width: get-vw(140);
      }

      img {
        @include cover();
      }
    }
  }

  &__gallery {
    position: relative;
    margin-top: 40px;

    .step {
      @include flex(center, flex-start);

      &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      &:not(.--active) {

        .visual__container {
          opacity: 0;
          transform: rotate(-6deg) translate(-40%, 10%);
          transition: transform 820ms $ease, opacity 420ms $ease;

          &:nth-child(2) {
            transform: rotate(6deg) translate(50%, 10%);
            transition-delay: 120ms;
          }
        }
      }

      .visual__container {
        opacity: 1;
        @include imgRatio(260, 310, $base);
        transform: rotate(3deg) translate(0%, 0%);
        transition: transform 820ms $ease, opacity 420ms $ease;

        &:nth-child(2) {
          @include imgRatio(260, 260, $base);
          transform: rotate(-3deg) translate(0%, 0%);
          margin-left: -3%;
        }
      }
    }
  }
}
