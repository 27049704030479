.WhatIDo {
  background: $nuit-ete-d1;
  color: $ivoire-base;

  @include responsive($tabletSmall) {
    padding: 120px 0;
  }

  .container {
    @include responsive($tabletSmall) {
      max-width: space(21);
    }
  }

  .block {
    @include flex(center, space-between);
    gap: 40px;
    flex-direction: column;

    @include responsive($tabletSmall) {
      flex-direction: row;
      gap: 0;
    }

    & + .block {
      margin-top: 80px;
    }

    > * {
      width: 100%;

      @include responsive($tabletSmall) {
        max-width: 50%;
      }
    }

    .block__content {
      @include responsive($tabletSmall) {
        padding-right: space(2);
      }
    }

    .visual__container {
      @include imgRatio(1, 1, $base)
    }

    &:nth-child(even) {
      .block__content {
        @include responsive($tabletSmall) {
          order: 2;
          padding-left: space(2);
          padding-right: 0;
        }
      }
    }
  }
}
