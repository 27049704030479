.NavSubmenu{
  transition: opacity 500ms $ease,
              transform 500ms $ease;

  @include responsive($menuBreakpoint, max){
    padding: 1em 0;
  }
  
  @include responsive($menuBreakpoint){
    background-color: #FCFCFC;
    position: absolute;
    top: 3em; left: 0;
    border-radius: 4px;
    padding: 2em;
    min-width: get-vw(600);
    z-index: 200;
  }

  @include responsive($deskXXL){
    max-width: 600px;
  }

  &:before {
    @include responsive($menuBreakpoint){
      @include content();
      width: 10px;
      height: 10px;
      background: $white;
      position: absolute;
      top: 0;
      left: 2em;
      transform: rotate(45deg) translateY(-50%);
    }
  }

  .subtitle {
    opacity: 0.7;
    margin-bottom: 1em;
    font-size: 14px;
    @include font-limited(14);
  }

  ul {
    display: grid;
    grid-gap: 0.4em 1em;

    @include responsive($menuBreakpoint){
      grid-template-columns: repeat(2, 1fr);
    }

    li {
      @include responsive($menuBreakpoint, max){
        grid-column: span 2;
      }
    }
  }

  li.--page {
    grid-column: span 2;

    @include responsive($menuBreakpoint){
      margin-top: 1em;
    }

    span {
      background-size: 100% 1px;
      background-position: 0% 100%;
    }
  }

  li.--submenu:not(.--active) &{
    @include responsive($menuBreakpoint, max){
      display: none;
    }

    @include responsive($menuBreakpoint){
      opacity: 0;
      pointer-events: none;
      transform: translate(0, 20%) scale(0.9);
    }
  }

  .Link{
    line-height: 1.2;

    .Icon svg > * {
      fill: $white;

      @include responsive($menuBreakpoint){
        fill: $nuit-ete-base;
      }
    }

    span{
      line-height: 1.2;
      background-image: linear-gradient($nuit-ete-base, $nuit-ete-base);

      @include responsive($menuBreakpoint, max){
        background-image: linear-gradient($white, $white);
      }
    }
  }
}