.Breadcrumb{
  .PageHeaderPrestation{
    margin-bottom: 1em;    
  }

  ul{
    @include flex(center, center);
    flex-wrap: wrap;

    li{
      &:not(:first-child) {
        @include flex(center);
  
        &:before{
          @include content();
          height: 12px;
          width: 12px;
          margin: 0.1em 0.25em 0;
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.8' d='M1.21094 8.11621L0.5 7.45996L3.26172 4.43848L0.609375 1.59473L1.2998 0.883789L5.53809 4.16504V4.67773L1.21094 8.11621Z' fill='%2345586F'/%3E%3C/svg%3E%0A");
          background-size: 100% 100%;
  
          @include responsiveMinMax($tabletSmall, $deskXXL){
            height: get-vw(12);
            width: get-vw(12);
          }
        }
      }

      > *{
        font: 700 14px $main-font;
        @include font-limited(14);
        text-transform: uppercase;
        display: block;
        line-height: 1.6;
        color: $nuit-ete-base;

        @include responsive($tabletSmall, max){
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 10em;
        }
      }
    }
  }
}