.ArticleCard{
  display: block;
  
  @include noTouchHover() {
    .head{
      .visual__container .visual{
        transform: scale(1.05);
      }

      .Categories{
        transform: scale(0.95);
      }

      .Macaron{
        transform: translate(15%, 15%) scale(0.9);
      
        @include responsive($tabletSmall){
          transform: translate(25%, 25%) scale(0.9);
        }
      }
    }
  }

  /*
  * Themes
  */

  @each $slug, $background, $color in $categories-themes {
    &.--#{$slug} &{
      .head {
        .Macaron{
          > circle{ fill: $color; }
          g.text-label > *{ fill: $background; }
          path.arrow{ fill: $background; }
        }
      }
    }
  }

  // Special styles for grid
  .AllArticles &{
    // .head .visual__container{
      // @include imgRatio(400, 400);
    // }

    // &:nth-child(4n + 2),
    // &:nth-child(4n + 3){
    //   .head{
    //     .visual__container{
    //       @include imgRatio(673, 400);
    //       min-height: 400px;
    //     }
    //   }
    // }
  }

  // Head
  .head{
    position: relative;

    .visual__container > .visual,
    .Categories{
      transition: transform 550ms $ease;
    }

    .visual__container{
      // @include imgRatio(330, 400);
      height: 400px;
      background: $base;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        height: get-vw(400);
      }
    }

    .Categories{
      position: absolute;
      top: 0; left: 0;
      padding: 1em;
    }

    .Macaron{
      position: absolute;
      bottom: 0; right: 0;
      transform: translate(15%, 15%);
      
      @include responsive($tabletSmall){
        transform: translate(25%, 25%);
      }
    }
  }

  // Content
  .wrapper{
    @include font-limited(14);
    padding: 1em 0.5em 0;

    > time{
      margin: 1em 0 0.5em;
    }

    // Title
    > div{
      font: 700 22px $main-font;
      @include font-limited(28);
      line-height: 1.1;
      color: #1A1A1A;
    }
  }

  swiper-slide & {
    width: 80vw;
    max-width: 330px;
  }
}
