.App {
  width: 100%;
  min-height: calc(var(--vh) * 100);
  position: relative;
  z-index: 10;

  html:not(.no-js) body.--loading & {
    pointer-events: none;
  }

  > * {
    width: 100%;

    & + * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.container {
  width: 100%;
  padding: 0 2.15em;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  @include responsive($tabletSmall) {
    // padding: 0 2.15em;
  }
}

section {
  position: relative;
  padding: 80px 0;

  @include responsive($tabletSmall) {
    padding: get-vw(100) 0;
  }
  
  &.--big{
    padding: 120px 0;
  
    @include responsive($tabletSmall) {
      padding: get-vw(150) 0;
    }
  }

  > .container > .headline {
    margin-bottom: 70px;
  }
}

.Popup{
  @include cover(fixed);
  z-index: 1000;
  padding: 3em;

  > *:first-child{
    position: relative;
    z-index: 1
  }

  .Close{
    position: absolute;
    top: 0.25em; right: 0.25em;
    z-index: 2;

    @include responsive($tabletSmall){
      top: 1.5em; right: 1.5em;
    }
  }

  .PopupOverlay{
    @include cover();
    background-color: rgba($black, 0.7);
  }
}

.grecaptcha-badge {
  opacity: 0;

  .--Contact & {
    opacity: 1;
  }
}

