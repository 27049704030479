.Gift{
  position: relative;
  
  > .container{
    z-index: 1;
    height: calc(var(--vh) * 100);
    max-height: 900px;
    
    @include responsive($tabletSmall, max){
      min-height: 550px;
      max-height: 650px;
    }
    
    // Content
    > .wrapper{
      align-items: flex-start;
      width: 100%;
      max-width: 330px;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(330);
      }

      @include responsive($tabletSmall){
        margin-left: auto;
        margin-right: 5em;
      }

      h2{
        margin-bottom: 0.35em;
      }
    }
  }

  // Background
  > .visual__container{
    z-index: 0;
    pointer-events: none;
    
    &, 
    &:after{
      @include cover();
    }

    &:after{
      @include content();
      background-color: rgba(#1A1A1A, 0.15);
    }
  }
}
